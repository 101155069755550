import React from 'react'
const pluralMap = {
  'hamper': 'hampers',
  'box': 'boxes'
}

/**
 * Convert a word to plural
 *
 * @param {string}  for // Word to be converted to plural
 * @param {boolean} [capitalize=false]
 * @param {string}  [fallback]
 */
const Plural = props => {
  let plural = ''
  const forWord = props.for.toLowerCase()

  if (pluralMap[forWord]) {
    plural = pluralMap[forWord]
  }
  else if (props.fallback) {
    plural = props.fallback
  }
  
  if (props.capitalize === true || props.capitalize === 'true') {
    plural = plural.charAt(0).toUpperCase() + plural.slice(1);
  }

  return <span className='plural-word'>{plural}</span>
}

export default Plural