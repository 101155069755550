import React from 'react'
import Phone from './Phone'
import Button from './Button'
import CtaStyles from '../../styles/Cta.module.sass'

/**
 * Display a call-to-action section
 *
 * @param {string}  [text]
 * @param {string}  [link]
 * @param {string}  [before]
 * @param {string}  [className]
 * @param {string}  [buttonText]
 */
const Cta = props => {
  let text = 'Contact Us'
  let link = '/contact/'

  if (props.text) {text = props.text}
  if (props.link) {link = props.link}

  return (
    <div className={`cta ${CtaStyles.cta || ''} ${props.className || ''}`}>
      {props.before ? <em>{props.before}</em> : null}
      
      <Phone className="has-icon" />

      <Button type={props.className ? props.className : 'is-primary'} text={props.buttonText | "Get in Touch"} />
    </div>
  )
}

export default Cta
