import React, { useState } from 'react'
import { Link } from 'gatsby'
import SiteMetadata from './SiteMetadata'
import logo from '../../static/media/logo.svg'
import MainMenu from '../data/menus.json'
import Phone from './shortcodes/Phone'
import Button from './shortcodes/Button'
import NavbarStyles from '../styles/Navbar.module.sass'

/**
 * Display the global header
 * 
 * @param {boolean} [solid=false]
 */
const Navbar = props => {
  const { title, phone } = SiteMetadata()

  // Grab menu from JSON using this slug
  const menuUsedSlug = 'main'

  const [navbarState, setNavbarState] = useState(false)

  const toggleState = (newState = !navbarState) => {
    setNavbarState(newState)
  }

  return (
    <>
      <div className="decorative-line above-navbar"></div>
      <nav
        className={`navbar is-transparent is-solid ${NavbarStyles.navbar || ''}`}
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container is-semi-narrow">
          <div className={`navbar-brand ${NavbarStyles.navbarBrand || ''}`}>
            <Link
              to="/"
              className={`navbar-item ${NavbarStyles.logoHolder || ''}`}
              title="Logo"
            >
              <img
                src={logo}
                alt={title}
                className={`${NavbarStyles.logo || ''}`}
              />
            </Link>

            {/* Hamburger menu */}
            <span
              onClick={() => toggleState()}
              onKeyDown={() => toggleState()}
              className={`navbar-burger burger ${navbarState ? 'is-active' : '' || ''
                }`}
              role="button"
              tabIndex="0"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </span>

            {/* Mobile Call Button */}
            <a
              href={`tel:${phone}`}
              className={`mobile-phone-button is-hidden-desktop ${NavbarStyles.mobilePhoneButton ||
                ''}`}
            >
              Call
            </a>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${navbarState ? 'is-active' : ''} ${NavbarStyles.menu || ''}`}
          >
            <div className={`navbar-start ${NavbarStyles.navbarStart || ''}`}>
              {MainMenu.menus
                .find(menu => menu.title === menuUsedSlug)
                .links.map(({ text, link, links }) =>
                  links ? (
                    <div
                      key={text + link}
                      className="navbar-item has-dropdown is-hoverable"
                    >
                      <Link
                        key={text}
                        to={link}
                        className="navbar-link"
                        activeClassName="is-active"
                        onClick={() => {
                          setNavbarState(false)
                        }}
                        dangerouslySetInnerHTML={{__html: text}}
                      />
                      <div
                        className={`navbar-dropdown ${NavbarStyles.navbarDropdown ||
                          ''}`}
                      >
                        {links.map(({ text, link }) => (
                          <Link
                            key={text}
                            to={link}
                            className="navbar-item"
                            activeClassName="is-active"
                            onClick={() => {
                              setNavbarState(false)
                            }}
                            dangerouslySetInnerHTML={{ __html: text }}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Link
                      key={text}
                      to={link}
                      className="navbar-item"
                      activeClassName="is-active"
                      onClick={() => {
                        setNavbarState(false)
                      }}
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  )
                )}
            </div>
            <div className={`navbar-end ${NavbarStyles.navbarEnd || ''}`}>
              <Phone className={`has-icon is-link ${NavbarStyles.phone || ''}`} />
              <Button type="is-link" class={`is-link ${NavbarStyles.ctaButton || ''}`} text="Contact Us" />
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
