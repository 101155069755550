const vatFactor = 1.23

/**
 * Round and fix number without decimal points
 * 
 * @param {number}	number
 */
export const roundAndFix = (number) => {
	// Round the number correctly
	let _number = +(Math.round(number + 'e+2') + 'e-2')

	// Return the number with 2 decimal places
	// return _number.toFixed(2) // type: String
	return _number.toFixed(0) // type: String
}

/**
 * Remove VAT from number
 * 
 * @param {number}	number
 */
export const removeVatFrom = number => {
	// Add VAT
	let numberWithoutVat = +number / vatFactor

	return roundAndFix(numberWithoutVat) // type: String
}

/**
 * Add VAT to number
 * 
 * @param {number}	number
 */
export const addVatTo = number => {
	// Add VAT
	let numberWithVat = +number * vatFactor

	return roundAndFix(numberWithVat) // type: String
}