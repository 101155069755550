import React from 'react'
import { Link } from 'gatsby'
import Button from './shortcodes/Button'
import logo from '../../static/media/logo.svg'
import SiteMetadata from './SiteMetadata'
import FooterStyles from '../styles/Footer.module.sass'

/**
 * Display the global footer
 */
const Footer = props => {
  const { title } = SiteMetadata()

  return (
    <>
      <div className="decorative-line above-footer"></div>

      <footer className={`footer ${FooterStyles.footer || ''}`}>
        <div className="container has-text-centered is-semi-narrow">
          <p>
            <Link
              to="/"
              className={FooterStyles.logoHolder || ''}
              title="Logo"
            >
              <img
                src={logo}
                alt={title}
                className={`${FooterStyles.logo || ''}`}
              />
            </Link>
            Copyright{' '}
            <span className={FooterStyles.currentYear || ''}>{new Date().getFullYear()}</span>{' '}
            <strong>{title}</strong>. All rights reserved.{' '} <Link to="/terms">T&C</Link>

            <Button />
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
