import React, { useState } from 'react'
import Map from '../Map'
import FaqSection from './FaqSection'
import SiteMetadata from '../SiteMetadata'
import ContactFormStyles from '../../styles/ContactForm.module.sass'

/**
 * Display a functional contact form
 *
 * @param {string}  className
 */
const ContactForm = props => {
  const { email, phone } = SiteMetadata()

  const initialEmailSubjectString = 'BreastPump Enquiry'

  const [emailSubject, setEmailSubject] = useState(initialEmailSubjectString)

  const handleSetEmailSubject = (customerName) => {
    if (customerName && customerName.trim().length) {
      setEmailSubject(initialEmailSubjectString + ' from ' + customerName)
    }
    else {
      setEmailSubject(initialEmailSubjectString)
    }
  }

  return (
    <div className={`is-clearfix contact-form-holder ${ContactFormStyles.contactFormHolder || ''}`}>
      <div className={`container is-semi-narrow ${ContactFormStyles.contactFormContainer || ''}`}>
        <div className={`form-container ${ContactFormStyles.form || ''} ${props.className || ''}`}>
          <div>
            <form
              name="BreastPump Enquiry"
              method="post"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="first-name"
            >
              <div>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="BreastPump Enquiry" />
                <input type="hidden" name="subject" value={emailSubject} />
                <div hidden>
                  <label>
                    Trap for non-humans: <input name="first-name" />
                  </label>
                </div>
                <div className="field">
                  <div className="control">
                    <label htmlFor="name">
                      Your Name
                    </label>
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      id={'name'}
                      required={true}
                      onChange={e => handleSetEmailSubject(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label htmlFor="email">
                      Your Email
                    </label>
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label htmlFor="phone">
                      Phone Number
                    </label>
                    <input
                      className="input"
                      type={'tel'}
                      name={'phone'}
                      id={'phone'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label htmlFor="message">
                      Message
                    </label>
                    <textarea
                      className="textarea"
                      name={'message'}
                      id={'message'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button
                    className={`button is-link ${ContactFormStyles.submit || ''}`}
                    type="submit"
                  >
                    Send Enquiry
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={ContactFormStyles.mapHolder}>
          <h3 class="title is-3">Contact Information</h3>
          <p><strong>Phone: </strong>{phone}</p>
          <p><strong>Email: </strong><a href={`mailto:${email}`}>{email}</a></p>
          <FaqSection />
        </div>
      </div>
    </div>
  )
}

export default ContactForm
